import React, { useState, useContext } from 'react';
import { graphql } from 'gatsby';
import { Link } from '@reach/router';
import SEO from '../components/seo';
import Layout from '../components/Layout';
import styled from 'styled-components';
import HotelCardsCarousel from 'components/HotelCardsCarousel';
import { Container, TitleContainer } from 'ui/containers';
import Content from 'components/Content';
import { Buttons, Button, Paragraph, Heading, CarouselButton } from 'ui';
import { useTranslation } from 'react-i18next';
import PlacesPictureCarousel from 'components/PlacesPictureCarousel';
import ArticleCardsCarousel from 'components/ArticleCardsCarousel';
import Carousel, { Modal, ModalGateway } from 'react-images';
import AuthContext from 'contexts/auth';
import PageContext from 'contexts/page';
import { useDataLayerBuilder } from '@tymate/react-gtm';

const CityName = styled.div`
  color: #ffffff;
  font-size: 68px;
  font-weight: 700;
  text-align: center;
  align-items: center;
  margin-bottom: ${({ theme }) => theme.spacing(2)};
`;

const BlockWrapper = styled.div`
  position: absolute;
  top: 50%;
  transform: translateY(-50%);
  display: block;
  text-align: center;
  align-items: center;
  width: 100%;
`;

const NumberHotels = styled.div`
  font-size: 16px;
  font-weight: 600;
  border: 2px solid #fff;
  margin-right: auto;
  margin-left: auto;
  border-radius: 4px;
  text-decoration: none;
  padding: ${({ theme }) => theme.spacing(0.25)}
    ${({ theme }) => theme.spacing(0.5)};
  color: #fff;
  width: fit-content;
  transition: all 150ms ease;

  &:hover {
    border: 2px solid ${({ theme }) => theme.primary} !important;
    color: ${({ theme }) => theme.primary};
  }
`;

const CountryName = styled(Heading)`
  color: #ffffff;
`;

const City = ({ data: { city } }) => {
  const { t } = useTranslation();
  const { isAuthenticated } = useContext(AuthContext);
  const { getLanguagePrefix } = useContext(PageContext);

  useDataLayerBuilder(({ city }) => ({
    pageType: 'city',
    searchCity: city.title,
    searchCityId: city.originalId,
  }));

  const [modalIsOpen, setModalIsOpen] = useState(false);

  const country = city.country;

  const images = city.attachments.map(({ largeUrl, displayName }) => {
    return { src: largeUrl, caption: displayName };
  });
  return (
    <Layout>
      <SEO title={city.title} />
      <ModalGateway>
        {modalIsOpen ? (
          <Modal onClose={() => setModalIsOpen(false)}>
            <Carousel views={images} />
          </Modal>
        ) : null}
      </ModalGateway>
      <PlacesPictureCarousel pictures={city.attachments} isBanner>
        <BlockWrapper>
          <CountryName>{country.title}</CountryName>
          <CityName>{city.title}</CityName>
          <NumberHotels
            as={Link}
            to={`${getLanguagePrefix(t('url.hotels'))}?cityId=${
              city.originalId
            }`}
          >
            {t('country.hotelsCount', {
              count: city.hotels.totalCount,
            })}
          </NumberHotels>
        </BlockWrapper>
        <CarouselButton onClick={() => setModalIsOpen(true)}>
          {t('utils.pictures')}
        </CarouselButton>
      </PlacesPictureCarousel>
      <Container>
        <Paragraph>
          <Content html={city.description} />
        </Paragraph>

        <TitleContainer>{t('city.nearby.title')}</TitleContainer>
        <Paragraph>
          <Content html={city.cityInterest} />
        </Paragraph>
        <TitleContainer>{t('city.unavoidable.title')}</TitleContainer>
        <Paragraph>
          <Content html={city.nearbyInterest} />
        </Paragraph>
        <TitleContainer>{t('city.transport.title')}</TitleContainer>
        <Paragraph>
          <Content html={city.accessCityTransports} />
          <Content html={city.inCityTransports} />
        </Paragraph>
      </Container>
      {Boolean(city.hotels.nodes.length) && (
        <>
          <Container>
            <TitleContainer bold>
              {t('city.hotel.title', { countryName: city.title })}
            </TitleContainer>
          </Container>
          <HotelCardsCarousel
            hotels={city.hotels.nodes}
            isAuthenticated={isAuthenticated}
          />
          <Container>
            <Buttons>
              <Button
                as={Link}
                variant="secondary"
                to={`${getLanguagePrefix(t('url.hotels'))}?cityId=${
                  city.originalId
                }`}
              >
                {t('city.hotel.button')}
              </Button>
            </Buttons>
          </Container>
        </>
      )}
      {Boolean(city.articles.length) && (
        <Container>
          <TitleContainer bold>
            <TitleContainer bold>{t('home.blog.title')}</TitleContainer>
          </TitleContainer>
          <ArticleCardsCarousel articles={city.articles} />
          <Buttons hasNoTopMargin>
            <Button variant="secondary">{t('city.article.button')}</Button>
          </Buttons>
        </Container>
      )}
    </Layout>
  );
};

export const pageQuery = graphql`
  query($language: String!, $id: String!) {
    city(language: { eq: $language }, id: { eq: $id }) {
      id
      originalId
      title
      lat
      lon
      description
      nearbyInterest
      cityInterest
      accessCityTransports
      inCityTransports
      attachments {
        id
        mediumUrl
        largeUrl
        smallUrl
        displayName
      }
      country {
        title
        originalId
      }
      hotels {
        totalCount
        nodes {
          id
          slug
          displayName
          sustainable {
            level
          }
          city {
            slug
            title
            country {
              title
              slug
            }
          }
          primaryAttachment {
            smallUrl
          }
        }
      }
      articles {
        id
        displayName
        publishedAt
        excerpt
        picture {
          imagePath
        }
        thematic {
          displayName
        }
      }
    }
  }
`;

export default City;
