import React, { useState, useMemo } from 'react';
import { useDeepCompareEffect } from 'react-use';
import styled from 'styled-components';
import { HotelCard } from '../ui/cards';
import Slider from 'react-slick';
import { media } from '../ui';
import 'slick-carousel/slick/slick.css';
import 'slick-carousel/slick/slick-theme.css';
import RightArrowSlider from '../assets/images/RightArrowSlider.svg';
import LeftArrowSlider from '../assets/images/LeftArrowSlider.svg';
import { useMedia } from 'react-use';
import { useGTM } from '@tymate/react-gtm';

const Carousel = styled.div`
  width: calc(100% - 36px);
  margin-left: auto;
  margin-right: auto;

  .slick-slider {
    .slick-next,
    .slick-prev {
      width: 36px;
      height: auto;
      top: 138px;
      z-index: 30;
    }

    .slick-list {
      margin-right: ${props => (props.isBanner ? 0 : '45px')};
      margin-left: ${props => (props.isBanner ? 0 : '45px')};
    }

    ${media.tablet`
      .slick-list {
        margin-right: ${props => (props.isBanner ? 0 : '64px')};
        margin-left: ${props => (props.isBanner ? 0 : '64px')};
      }
    `}

    .slick-next {
      right: 10px;

      ${media.tablet`
        right: 25px;
      `}
    }

    .slick-prev {
      left: 10px;

      ${media.tablet`
        left: 25px;
      `}
    }
  }
`;

const HotelCardsCarousel = ({ hotels = [], isBanner, isAuthenticated }) => {
  const { pushDataLayer } = useGTM();
  const [firstSlide, setFirstSlide] = useState(0);
  const isMobile = useMedia('(max-width: 750px)');
  const isTablet = useMedia('(min-width: 750px) and (max-width: 1000px)');

  const slidesToShow = isMobile
    ? 1
    : isTablet
    ? hotels && hotels.length < 3
      ? hotels.length
      : 2
    : hotels && hotels.length < 4
    ? hotels.length
    : 3;

  const slidesToScroll = isMobile ? 1 : isTablet ? 2 : 3;

  const shownSlides = useMemo(
    () =>
      (hotels || [])
        .map((hotel, index) => ({ hotel, index }))
        .slice(firstSlide, firstSlide + slidesToShow),
    [hotels, firstSlide, slidesToShow],
  );

  useDeepCompareEffect(() => {
    pushDataLayer({
      ecommerce: {
        impressions: shownSlides.map(({ hotel, index }) => ({
          id: hotel?.originalId,
          name: hotel?.displayName,
          brand: 'Feelingo',
          category: 'Hotel',
          position: index + 1,
        })),
      },
    });
  }, [shownSlides]);

  const settings = {
    dots: false,
    infinite: true,
    speed: 500,
    slidesToShow,
    slidesToScroll,
    initialSlide: 0,
    autoplay: false,
    arrows: true,
    nextArrow: <img src={RightArrowSlider} alt="" />,
    prevArrow: <img src={LeftArrowSlider} alt="" />,
    afterChange: slide => setFirstSlide(slide),
  };

  return (
    <Carousel isBanner={isBanner}>
      <Slider {...settings} style={{ marginLeft: -16, marginRight: -16 }}>
        {(hotels || []).map((hotel, index) => (
          <HotelCard
            {...hotel}
            isCarousel
            isAuthenticated={isAuthenticated}
            openInNewTab
          />
        ))}
      </Slider>
    </Carousel>
  );
};

export default HotelCardsCarousel;
