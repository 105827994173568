import React from 'react';
import { ArticleCard } from 'ui/cards';
import { Grid, Cell } from 'ui';
import 'slick-carousel/slick/slick.css';
import 'slick-carousel/slick/slick-theme.css';
import { GridContainer } from 'ui/containers';



const ArticleCardsCarousel = ({ articles, isLinkExternal }) => {

  return (
    <>
      <GridContainer variant="explore">
        {(articles || []).map((article, index) => (
          <Grid>
            <Cell sizes={{ default: 1, desktop: 1 }}>
              <ArticleCard
                {...article}
                isCarousel
                external={isLinkExternal}
              />
            </Cell>
          </Grid>
        ))}
      </GridContainer>
    </>
  );
};

export default ArticleCardsCarousel;
