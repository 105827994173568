import React from 'react';
import styled from 'styled-components';
import Slider from 'react-slick';
import 'slick-carousel/slick/slick.css';
import 'slick-carousel/slick/slick-theme.css';

const Carousel = styled.div`
  width: 100%;
  margin-left: auto;
  margin-right: auto;
  position: relative;
  outline:none;
  
  .slick-slider {
    .slick-next,
    .slick-prev {
      width: 36px;
      height: 300px;
      top: 138px
      z-index: 30;
    }

    .slick-list {
      margin-right: ${props => (props.isBanner ? 0 : '64px')};
      margin-left: ${props => (props.isBanner ? 0 : '64px')};
    }

    .slick-next {
      right: 20px;
    }

    .slick-prev {
      left: 20px;
    }
  }
`;

const Picture = styled.div`
  outline: none;
  background-image: linear-gradient(
      0deg,
      rgba(0, 0, 0, 0.3),
      rgba(0, 0, 0, 0.3)
    ),
    url("${props => props.imageUrl}");
  background-repeat: no-repeat;
  background-position: center center;
  background-size: cover;
  width: 100%;
  height: 525px;
  z-index: 0;
  margin-right: -64px;
  display: flex;
  align-items: center;
  text-align: center;
`;

const PlacesPictureCarousel = ({ pictures, isBanner, children }) => {
  const settings = {
    infinite: true,
    speed: 1500,
    slidesToShow: 1,
    slidesToScroll: 1,
    initialSlide: 1,
    autoplaySpeed: 5000,
    dots: false,
    autoplay: true,
    arrows: false,
    pauseOnHover: true,
    fade: true,
  };

  return (
    <Carousel isBanner={isBanner}>
      <Slider {...settings}>
        {(pictures || []).map(({ largeUrl }, index) => (
          <Picture key={index} imageUrl={largeUrl}>
            {children}
          </Picture>
        ))}
      </Slider>
    </Carousel>
  );
};

export default PlacesPictureCarousel;
